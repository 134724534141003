import React, {lazy, useState} from 'react';
import HIcon from "../../payment/components/HIcon.tsx";
import HStatus from "../../../../_mesomb/partials/components/HStatus.tsx";
import {KTIcon} from "../../../../_mesomb/helpers";
import ActionButton from "../../../../_mesomb/partials/components/ActionButton.tsx";
import Stat from "../../../../_mesomb/partials/components/Stat.tsx";
import {formatDate} from "date-fns/format";
import ModalProductForm from "./ModalProductForm.tsx";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const HChart = lazy(() => import('../../../../_mesomb/partials/components/HChart.tsx'))

function Product({product}) {
  const [object, setObject] = useState(product)
  const navigate = useNavigate();
  const {t} = useTranslation()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
        <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative me-5">
          <HIcon image={object.image_url} text={object.name} size={150}/>
        </div>
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center mb-1">
                <a href="#"
                   className="text-gray-800 text-hover-primary fs-2 fw-bold me-3">{object.name}</a>
                {object.archived && <HStatus label={t('Archived')} status={'ARCHIVED'} />}
              </div>
              <div className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">{object.description}</div>
            </div>
            <div className="d-flex mb-4">
              <a onClick={() => {
                setShowModal(true)
              }}
                 className="btn btn-light-primary btn-sm me-3">
                <KTIcon iconName={'pencil'} />
                {t('Edit')}
              </a>
              {!object.archived && (
                <ActionButton
                  icon={'trash'}
                  title={t('Delete')}
                  action={{path: `/products/${object.id}/`, method: 'delete'}}
                  confirmation={{message: t('Product_delete_confirmation')}}
                  className={'btn btn-danger btn-sm'}
                  onFinish={() => {
                    navigate('/products/')
                  }}
                />
              )}
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-start">
            <div className="d-flex flex-wrap">
              <Stat label={t('Price')} value={t('intlCurrency', {value: object.default_price, currency: object.default_price_currency})} prefix={object.default_price_currency} />
              <Stat label={t('Created')} value={formatDate(object.created_at, 'PPpp')} />
              <Stat label={t('Total Sells')} value={object.sells} type={'counter'} />
              <Stat label={t('Last Sell')} value={object.last_sell ? formatDate(object.last_sell, 'PPpp') : 'N/A'} />
            </div>
          </div>
        </div>
      </div>

      <div className="card card-flush overflow-hidden">
        <div className="card-header py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark">{t('Last 30 days Sells')}</span>
          </h3>
        </div>
        <div className="card-body d-flex justify-content-between flex-column p-5">
          <HChart type={'area'} data={object.history} height={300}/>
        </div>
      </div>

      <ModalProductForm
        show={showModal}
        onClose={() => setShowModal(false)}
        selected={object}
        onFinish={(data) => {
          setObject({...object, ...data})
          setShowModal(false)
        }}
      />
    </>
  );
}

export default Product;