import React, {lazy, Suspense, useEffect} from 'react'
import {LayoutProvider, LayoutSplashScreen} from '../_mesomb/layout/core'
import {MasterInit} from '../_mesomb/layout/MasterInit'
import * as Sentry from "@sentry/react";
import {ThemeModeProvider, useThemeMode} from '../_mesomb/partials/layout/theme-mode/ThemeModeProvider'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
  RouterProvider,
  useLocation,
  useNavigationType
} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "./store/hooks.ts";
import {selectUser, setAuth, setUser} from "./store/auth.ts";
import {setApplication} from "./store/application.ts";
import {ErrorsPage} from "./modules/errors/ErrorsPage.tsx";
import axios from "axios";
import {
  ApplicationModel,
  mapApplicationModel,
  mapCustomer,
  TeamMember,
  Transaction
} from "./modules/payment/core/_models.ts";
import EmailVerification from "./modules/auth/components/EmailVerification.tsx";
import {AuthPage, setAuthUrl} from "./modules/auth";
import {MasterLayout} from "../_mesomb/layout/MasterLayout.tsx";
import SuspensedView from "../_mesomb/partials/components/SuspensedView.tsx";
import ProductsPage from "./modules/product/ProductsPage.tsx";
import {ThemeModeComponent} from "../_mesomb/assets/ts/layout";
import ErrorPage from "./modules/errors/ErrorPage.tsx";
import {setConfigs} from "./store/configs.ts";
import {setTerms} from "./store/terms.ts";
import Swal from "sweetalert2";
import i18n from "../_mesomb/i18n";
import linkMigration from "../data/links_ids.json";
import {selectAnnouncements, setAnnouncements} from "./store/announcements.ts";


const ApplicationsPage = lazy(() => import('./modules/payment/ApplicationsPage'))
const FundsPage = lazy(() => import('./modules/fund/FundsPage'))
const FundContributionsPage = lazy(() => import('./modules/fund/FundContributionsPage'))
const PaymentLinkPage = lazy(() => import('./modules/paymentlink/PaymentLinkPage.tsx'))
const BulkPaymentPage = lazy(() => import('./modules/bulkpayment/BulkPaymentPage.tsx'))
const CustomersPage = lazy(() => import('./modules/customer/CustomersPage.tsx'))
const AccountPage = lazy(() => import('./modules/account/AccountPage.tsx'))
const TransactionsPage = lazy(() => import('./modules/transactions/TransactionsPage.tsx'))
const DashbaordPage = lazy(() => import('./modules/home/DashbaordPage.tsx'))
const TermsConditionsPage = lazy(() => import('./modules/auth/TermsConditionsPage.tsx'))

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter,
);

const extracKey = (params): string => {
  let key = params['*'];
  if ((key?.length || 0) > 0) {
    if (key.endsWith('/')) {
      key = key.slice(0, -1);
    }
    if (key?.indexOf('/') > 0) {
      key = key.split('/')[0]
    }
  }
  return key
}

const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark';

const App = () => {
  const currentUser = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const {mode} = useThemeMode()
  const calculatedMode = mode === 'system' ? systemMode : mode;
  const announcements = useAppSelector(selectAnnouncements);

  const feedback = Sentry.feedbackIntegration({
      colorScheme: calculatedMode,
      autoInject: false,
      showBranding: false,
    })

  useEffect(() => {
    Sentry.init({
      dsn: "https://82cc5e3d55a36285c91a17358ccd22d3@sentry.hachther.com/31",
      integrations: [
        Sentry.linkedErrorsIntegration({
          limit: 7,
        }),
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        feedback,
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.extraErrorDataIntegration(),
        Sentry.httpClientIntegration(),
        Sentry.httpContextIntegration(),
        Sentry.breadcrumbsIntegration({
          console: true,
          dom: true,
          fetch: true,
          history: true,
          xhr: true,
        })
      ],
      environment: import.meta.env.VITE_APP_ENVIRONMENT,
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/mesomb\.hachther\.com\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      profilesSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      sendDefaultPii: true,
      beforeSend(event, hint) {
        console.log(event)
        console.log(hint)
        if (import.meta.env.VITE_APP_ENVIRONMENT != 'production') {
          console.error(event);
          return
        }
        if (['ERR_NETWORK', 'ETIMEDOUT', 'ECONNABORTED'].includes(hint?.originalException?.code)) {
          return;
        }
        // Check if it is an exception, and if so, show the report dialog
        // if (event.exception && event.event_id) {
        //   Sentry.showReportDialog({eventId: event.event_id});
        // }
        return event;
      },
      beforeBreadcrumb(breadcrumb, hint) {
        if (breadcrumb.category === 'xhr') {
          const data = {
            ...(breadcrumb.data || {}),
            body: hint?.xhr?.__sentry_xhr_v3__?.body,
          }
          if (hint?.xhr?.status >= 400) {
            data.response = hint?.xhr.responseText.substring(0, 300)
          }
          return { ...breadcrumb, data }
        }
        return breadcrumb;
      }
    });

    if (currentUser) {
      Sentry.setUser({
        id: currentUser!.id,
        email: currentUser!.email,
        username: currentUser!.username,
        geo: {
          country_code: currentUser!.country,
          region: currentUser!.region,
          city: currentUser!.town,
        }
      })
    }

    axios.get('configs/').then(({data}) => {
      dispatch(setConfigs(data))
    });
    if (currentUser) {
      axios.get('terms/').then(ret => {
        if (ret?.data) {
          dispatch(setTerms(ret.data))
        }
      });
      axios.get('announcements/').then<Record<string, never>[]>(ret => {
        if (ret?.data) {
          dispatch(setAnnouncements(ret.data));
        }
      });
    }
    const ws = new WebSocket(`${import.meta.env.VITE_APP_SOCKET_HOST}/notificator/`)
    ws.onmessage = (message: string) => {
      const data = JSON.parse(message.data);
      if (data.type === 'announcement') {
        const index = announcements.findIndex(a => a.id == data.data.id)
        if (index > 0) {
          announcements[index] =  data.data;
          dispatch(setAnnouncements(announcements))
        } else {
          dispatch(setAnnouncements([...announcements, data.data]))
        }
      }
    }

    return () => {
      ws.close();
    };
  }, []);

  const router = sentryCreateBrowserRouter([
    {
      path: "/error/*",
      element: <ErrorsPage/>,
    },
    // {
    //   path: "/logout",
    //   element: <Logout/>,
    // },
    // {
    //   path: "/pay/:key/",
    //   element: <PayWithLinkPage/>,
    //   loader: async ({request, params}) => {
    //     const {data} = await axios.get<Array<ApplicationModel>>(`/payment/pay/${params.key}/`);
    //     return {link: data}
    //   },
    // },
    {
      path: "/auth/confirm-email/:key",
      element: <EmailVerification/>,
      loader: async ({request, params}) => {
        const {data} = await axios.post(setAuthUrl('auth/email/verify'), {key: params.key})
        dispatch(setAuth(data.meta))
        if (data.meta.is_authenticating) {
          const {data: profile} = await axios.get('profile/')
          dispatch(setUser(profile))
          window.location.href = '/dashboard'
        } else {
          window.location.href = '/auth/login'
        }
        return {}
      },
    },
    {
      path: '/invitations/accept-invite/:key',
      loader: async ({request, params}) => {
        const {data} = await axios.post(`profile/accept-invite/${params.key}`)
        if (data.meta.is_authenticating) {
          const {data: profile} = await axios.get('profile/')
          dispatch(setUser(profile))
          window.location.href = '/dashboard'
        } else {
          if (data.message) {
            Swal.fire({
              title: i18n.t('Error'),
              text: data.message.text,
              icon: data.message.type,
              buttonsStyling: false,
              confirmButtonText: i18n.t('Ok got it'),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            }).then(result => {
              window.location.href = data.redirect;
            })
          } else {
            window.location.href = data.redirect;
          }
        }
        return {}
      }
    },
    ...(currentUser ? [] : [{
      path: "/auth/*",
      element: <AuthPage />,
    }, {
      path: '/web/payment/',
      loader: async ({request, params}) => {
        const link = linkMigration.find(l => l.id == new URL(request.url).searchParams.get('widget'));
        location.href = `/pay/${link.key}`
      }
    }, {
      path: '/:lang/web/payment/',
      loader: async ({request, params}) => {
        const link = linkMigration.find(l => l.id == new URL(request.url).searchParams.get('widget'));
        location.href = `/pay/${link.key}`
      }
    }, {
      path: '/terms-and-conditions',
      element: <TermsConditionsPage />,
    }, {
      path: "*",
      element: <Navigate to="/auth"/>,
    }]),
    ...(currentUser ? [{
      path: "/*",
      element: <MasterLayout/>,
      errorElement: import.meta.env.VITE_APP_ENVIRONMENT != 'production' ? undefined : <ErrorPage feedback={feedback} />,
      children: [
        {
          path: 'auth/*',
          element: <Navigate to='/dashboard'/>
        },
        {
          path: 'dashboard/*',
          element: (
            <SuspensedView>
              <DashbaordPage product={'business'}/>
            </SuspensedView>
          ),
        },
        {
          path: 'applications/*',
          element: (
            <SuspensedView>
              <ApplicationsPage/>
            </SuspensedView>
          ),
          loader: async ({request, params}) => {
            const result = {}
            let key = extracKey(params)
            if (key?.length == 0 || ['add', 'create'].includes(key)) {
              const {data} = await axios.get<Array<ApplicationModel>>(`business/applications/`);
              result.applications = data.map(d => mapApplicationModel(d));
            } else {
              const {data} = await axios.get<Array<ApplicationModel>>(`business/applications/${key}/`);
              dispatch(setApplication(data));
              result.application = mapApplicationModel(data);
            }
            return result
          },
          // errorElement: (
          //   <Error500 />
          // ),
          // ErrorBoundary: ErrorBoundary
        },
        {
          path: 'fundraising/contributions/*',
          element: (
            <SuspensedView>
              <FundContributionsPage/>
            </SuspensedView>
          ),
          loader: async ({request, params}) => {
            const result = {}
            let key = extracKey(params)
            if (key?.length == 0 || ['add', 'contributions'].includes(key)) {
              const {data} = await axios.get<Array<ApplicationModel>>(`fundraising/funds/`);
              result.funds = data.map(d => mapApplicationModel(d));
            } else {
              const {data} = await axios.get<Array<any>>(`fundraising/contributions/${key}/`);
              result.contribution = data;
            }
            return result
          },
        },
        {
          path: 'fundraising/funds/*',
          element: (
            <SuspensedView>
              <FundsPage/>
            </SuspensedView>
          ),
          loader: async ({request, params}) => {
            const result = {}
            let key = extracKey(params)
            if (key?.length == 0 || ['add', 'contributions'].includes(key)) {
              const {data} = await axios.get<Array<ApplicationModel>>(`fundraising/funds/`);
              result.funds = data.map(d => mapApplicationModel(d));
            } else {
              const {data} = await axios.get<Array<any>>(`fundraising/funds/${key}/`);
              result.fund = mapApplicationModel(data);
            }
            return result
          },
        },
        {
          path: 'transactions/*',
          element: (
            <SuspensedView>
              <TransactionsPage/>
            </SuspensedView>
          ),
          loader: async ({request, params}) => {
            const result = {}
            let key = extracKey(params)
            if (key?.length == 0 || key == 'add') {
              const {data} = await axios.get<Array<ApplicationModel>>(`business/applications/`);
              result.applications = data.map(d => mapApplicationModel(d));
            } else {
              const {data} = await axios.get<Array<any>>(`business/transactions/${key}/`);
              result.transaction = data;
            }
            return result
          },
        },
        {
          path: 'business/links/*',
          element: (
            <SuspensedView>
              <PaymentLinkPage/>
            </SuspensedView>
          ),
          loader: async ({request, params}) => {
            const result = {}
            let key = extracKey(params)
            if (key?.length == 0 || ['add', 'create'].includes(key)) {
              // const {data} = await axios.get<Array<ApplicationModel>>(`business/links/`);
              // result.links = data;
            } else {
              const {data} = await axios.get<Array<ApplicationModel>>(`business/links/${key}/`);
              result.link = data;
            }

            if (request.url.endsWith('/create/') || request.url.endsWith('/edit/') || request.url.endsWith('/duplicate/')) {
              const {data} = await axios.get<Array<ApplicationModel>>(`business/applications/`);
              result.applications = data;
            }

            return result;
          },
        },
        {
          path: 'bulk/payments/*',
          element: (
            <SuspensedView>
              <BulkPaymentPage />
            </SuspensedView>
          ),
          loader: async ({request, params}) => {
            const result = {}
            let key = extracKey(params)
            if (key?.length == 0 || ['add', 'create'].includes(key)) {
              // const {data} = await axios.get<Array<ApplicationModel>>(`business/links/`);
              // result.links = data;
            } else if (key == 'run') {
              const {data} = await axios.get<Array<ApplicationModel>>(`bulk/lists/pull/`);
              result.lists = data;
            } else {
              const {data} = await axios.get<Array<ApplicationModel>>(`bulk/lists/${key}/`);
              result.list = data;
            }

            // if (request.url.endsWith('/create/') || request.url.endsWith('/edit/') || request.url.endsWith('/duplicate/')) {
            //   const {data} = await axios.get<Array<ApplicationModel>>(`business/applications/`);
            //   result.applications = data;
            // }

            return result;
          },
        },
        {
          path: 'bulk/transactions/*',
          element: (
            <SuspensedView>
              <BulkPaymentPage type={'transactions'} />
            </SuspensedView>
          ),
          loader: async ({request, params}) => {
            const result = {}
            let key = extracKey(params)
            if (key?.length == 0 || key == 'add') {
              const {data} = await axios.get<Array<ApplicationModel>>(`business/applications/`);
              result.applications = data.map(d => mapApplicationModel(d));
            } else {
              const {data} = await axios.get<Array<ApplicationModel>>(`business/transactions/${key}/?is_bulk=yes`);
              result.transaction = data;
            }
            return result
          },
        },
        {
          path: 'products/*',
          element: <ProductsPage/>,
          loader: async ({request, params}) => {
            const result = {}
            let key = extracKey(params)
            if (key?.length == 0 || key == 'add') {
              // const {data} = await axios.get<Array<ApplicationModel>>(`business/links/`);
              // result.links = data;
            } else {
              const {data} = await axios.get<Array<Transaction>>(`products/${key}/`);
              result.product = data;
            }

            return result;
          },
        },
        {
          path: 'customers/*',
          element: <CustomersPage/>,
          loader: async ({request, params}) => {
            const result = {}
            let key = extracKey(params)
            if (key?.length == 0 || key == 'add') {
              // const {data} = await axios.get<Array<ApplicationModel>>(`business/links/`);
              // result.links = data;
            } else {
              const {data} = await axios.get<any>(`customers/${key}/`);
              result.customer = mapCustomer(data);
            }

            return result;
          },
        },
        {
          path: 'account/*',
          element: <AccountPage/>,
          loader: async ({request, params}) => {
            const {data} = await axios.get<Array<Transaction>>(`profile/`);
            const result = {profile: data}
            const endpath = params['*']
            if (endpath?.startsWith('team')) {
              const {data} = await axios.get<Array<TeamMember>>(`teams/`);
              result.teams = data;
            }
            if (endpath?.startsWith('account')) {
              const {data} = await axios.get<Array<TeamMember>>(setAuthUrl('config'));
              result.authConfigs = data;
            }
            if (endpath?.startsWith('security')) {
              const {data: {data: authenticators}} = await axios.get<Array<TeamMember>>(setAuthUrl('account/authenticators'));
              result.otp = {authenticators};
              try {
                const {data: totp} = await axios.get<Array<TeamMember>>(setAuthUrl('account/authenticators/totp'));
                // result.authenticators = data.data;
              } catch (error) {
                result.otp.secret = error.response.data.meta.secret
              }
              // console.log('result.authenticators', totp)
            }
            if (endpath?.startsWith('developer')) {
              const {data} = await axios.get<Array<TeamMember>>('apiaccess/');
              result.api_accesss = data;
            }
            if (endpath?.startsWith('profile')) {
              const {data} = await axios.get<Array<TeamMember>>('bank/accounts/');
              result.bank_accounts = data;
            }
            return result
          },
        },
        {
          path: 'terms-and-conditions',
          element: <TermsConditionsPage />,
        },
        {
          path: '.well-known/apple-app-site-association',
          element: <TermsConditionsPage />,
        }
      ],
    }, {
      index: true,
      element: <Navigate to='/dashboard'/>
    }] : [])
  ]);

  return (
    <Sentry.ErrorBoundary
      fallback={({error, componentStack, resetError}) => {
        return (
          <ErrorPage error={error} feedback={feedback} />
        )
      }}
    >
      <Suspense fallback={<LayoutSplashScreen/>}>
        <LayoutProvider>
          <ThemeModeProvider>
            <MasterInit/>
            <RouterProvider router={router}/>
          </ThemeModeProvider>
        </LayoutProvider>
      </Suspense>
    </Sentry.ErrorBoundary>
  )
}

export default Sentry.withProfiler(App);
