import React, {useMemo, useRef, useState} from 'react';
import {Column} from "react-table";
import {Product} from "../../payment/core/_models.ts";
import CustomHeader from "../../../../_mesomb/partials/table/CustomHeader.tsx";
import I18n from "../../../../_mesomb/i18n";
import MediaInfoCell from "../../../../_mesomb/partials/table/MediaInfoCell.tsx";
import {KTIcon, QUERIES, toAbsoluteStatic} from "../../../../_mesomb/helpers";
import NumberCell from "../../../../_mesomb/partials/table/NumberCell.tsx";
import DateCell from "../../../../_mesomb/partials/table/DateCell.tsx";
import PaginatedList from "../../../../_mesomb/partials/table/PaginatedList.tsx";
import ActionButton from "../../../../_mesomb/partials/components/ActionButton.tsx";
import {useTranslation} from "react-i18next";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ModalProductForm from "./ModalProductForm.tsx";

function Products() {
  const ref = useRef(null);
  const [selected, setSelected] = useState(undefined)
  const [showModal, setShowModal] = useState(false)
  const {t} = useTranslation()
  const columns = useMemo<ReadonlyArray<Column<Product>>>(() => [
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Name')} className='min-w-250px' />,
      id: 'name',
      Cell: ({...props}) => <MediaInfoCell media={{
        url: `/products/${props.data[props.row.index].id}/`,
        title: props.data[props.row.index].name,
        subtitle: props.data[props.row.index].description,
        avatar: props.data[props.row.index].image_url ? toAbsoluteStatic(props.data[props.row.index].image_url) : undefined,
      }} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Price')} className='w-175px' />,
      accessor: 'default_price',
      Cell: ({...props}) => <NumberCell value={props.data[props.row.index].default_price} currency={props.data[props.row.index].currency} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Create')} className='w-120px' />,
      accessor: 'created_at',
      Cell: ({...props}) => <DateCell value={props.data[props.row.index].created_at} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Update')} className='w-120px' />,
      accessor: 'updated_at',
      Cell: ({...props}) => <DateCell value={props.data[props.row.index].updated_at} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Sells')} className='w-100px' />,
      accessor: 'sells',
      Cell: ({...props}) => <NumberCell value={props.data[props.row.index].sells} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Last Sell')} className='w-12px' />,
      accessor: 'last_sell',
      Cell: ({...props}) => <DateCell value={props.data[props.row.index].last_sell} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Actions')} className='text-end min-w-100px' />,
      id: 'actions',
      Cell: ({...props}) => (
        <div>
          <button className={'btn btn-icon btn-sm btn-primary me-2'} onClick={() => {
            setSelected(props.data[props.row.index])
            setShowModal(true)
          }}><KTIcon iconName={'pencil'} /></button>
          <ActionButton
            icon={'trash'}
            action={{path: `/products/${props.data[props.row.index].id}/`, method: 'delete'}}
            className={'btn btn-icon btn-sm btn-danger'}
            confirmation={{
              title: t('Confirmation'),
              message: t('Are you sur you want to preform this action? This action irreversible.'),
            }}
            onFinish={() => {
              ref.current?.click()
            }}
          />
        </div>
      ),
    },
  ], []);

  return (
    <>
      <PaginatedList
        ref={ref}
        url={`/products/`}
        columns={columns}
        title={(
          <button type='button' className='btn btn-primary' onClick={() => {
            setSelected(undefined)
            setShowModal(true)
          }}>
            <KTIcon iconName='plus' className='fs-2'/>
            {t('New Product')}
          </button>
        )}
        showSearch={true}
        queryList={QUERIES.PRODUCT_LIST}
      />

      <ModalProductForm
        show={showModal}
        onClose={() => setShowModal(false)}
        selected={selected}
        onFinish={(data) => {
          setShowModal(false);
          setSelected(false)
          // queryClient.invalidateQueries([`${QUERIES.PRODUCT_LIST}-${query}`])
          toast.info(selected ? t('Product successfully updated') : t('Product successfully created'))
          ref.current?.click()
        }}
      />

      <ToastContainer/>
    </>
  );
}

export default Products;